export const SEARCHED_NEWS = 'SEARCHED_NEWS';
export const SET_SAVED = 'SET_SAVED';
export const SET_LOADING = 'SET_LOADING';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const NOT_FOUND = 'NOT_FOUND';
export const SET_QUERY = 'SET_QUERY';
export const SET_NOT_SAVED = 'SET_NOT_SAVED';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SAVED_CARDS = 'SAVED_CARDS';
export const CLEAR_NEWS = 'CLEAR_NEWS';
